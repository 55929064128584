<script setup lang="ts">
const consentCookie = useCookie<boolean>("cookie-consent");

const infoPopupOpen = ref(false);

function consent() {
  consentCookie.value = true;
  useGTag();
}

function decline() {
  consentCookie.value = false;
}
</script>

<template>
  <div v-if="consentCookie === undefined" class="fixed bottom-0 grid place-items-center w-full z-[70]">
    <Popup v-model:open="infoPopupOpen" class="space-y-4">
      <h2 class="font-medium text-3xl text-center text-secondary-500">
        Cookies
      </h2>

      <p>
        Deze website maakt gebruikt van twee soorten cookies: essentiële cookies en analytische cookies. Essentiële
        cookies worden gebruikt voor de functionaliteit van de website en worden niet gebruikt voor analytische
        doeleinden en kunnen niet worden uitgeschakeld.
      </p>

      <p>
        Analytische cookies zijn optionele cookies die NMO gebruikt om te onderzoeken hoe gebruikers de website en
        dashboards gebruiken. Hierbij wordt het aantal paginaweergaven en unieke gebruikers per webpagina, de
        verkeersbron en het soort apparaat van de gebruikers bijgehouden. Ook wordt de locatie (land en plaats) van het
        bezoek en het soort browser bijgehouden. NMO gebruikt deze gegevens alleen voor interne analyse doeleinden om de
        website en dashboards te verbeteren.
      </p>

      <footer class="flex flex-col gap-2">
        <BaseButton @click="infoPopupOpen = false" color="primary" class="whitespace-nowrap !bg-cookieBar">
          Sluit popup
        </BaseButton>

        <BaseButton @click="decline" color="primary" class="whitespace-nowrap !bg-cookieBar">
          Alleen essentiële cookies
        </BaseButton>

        <BaseButton @click="consent" color="primary" class="whitespace-nowrap !bg-cookieBar">
          Accepteer alle cookies
        </BaseButton>
      </footer>
    </Popup>

    <section class="p-4 rounded-t-lg flex flex-col lg:flex-row gap-8 lg:gap-4 shadow-2xl bg-white border-cookieBar border-t-2 border-l-2 border-r-2 items-center max-w-6xl">
      <div class="w-full lg:mr-8">
        Deze site maakt gebruik van cookies voor essentiële functionaliteiten en analytische doeleinden.
      </div>

      <footer class="grid grid-cols-2 w-full lg:w-[unset] lg:flex lg:flex-row lg:gap-2 gap-2">
        <BaseButton @click="infoPopupOpen = true" color="primary" class="whitespace-nowrap col-span-2 !bg-cookieBar">
          Meer informatie
        </BaseButton>

        <BaseButton @click="decline" color="primary" class="whitespace-nowrap !bg-cookieBar">
          Alleen essentiële cookies
        </BaseButton>

        <BaseButton @click="consent" color="primary" class="whitespace-nowrap !bg-cookieBar">
          Accepteer alle cookies
        </BaseButton>
      </footer>
    </section>
  </div>
</template>

<style scoped>

</style>
